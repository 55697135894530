import routerOptions0 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qlgxtfb7g7jgiklqddgsr6qo2q/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/runner/work/Hangar/Hangar/frontend/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}