
// @ts-nocheck


export const localeCodes =  [
  "af",
  "ar",
  "bg",
  "ca",
  "cs",
  "da",
  "de",
  "dum",
  "el",
  "en",
  "es",
  "fi",
  "fr",
  "he",
  "hu",
  "it",
  "ja",
  "ko",
  "ku",
  "nl",
  "no",
  "pl",
  "pt",
  "ro",
  "ru",
  "srcyrl",
  "sv",
  "tr",
  "uk",
  "zhS",
  "zhT"
]

export const localeLoaders = {
  "af": [{ key: "../src/i18n/locales/processed/af.json", load: () => import("../src/i18n/locales/processed/af.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_af_json" */), cache: true }],
  "ar": [{ key: "../src/i18n/locales/processed/ar.json", load: () => import("../src/i18n/locales/processed/ar.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_ar_json" */), cache: true }],
  "bg": [{ key: "../src/i18n/locales/processed/bg.json", load: () => import("../src/i18n/locales/processed/bg.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_bg_json" */), cache: true }],
  "ca": [{ key: "../src/i18n/locales/processed/ca.json", load: () => import("../src/i18n/locales/processed/ca.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_ca_json" */), cache: true }],
  "cs": [{ key: "../src/i18n/locales/processed/cs.json", load: () => import("../src/i18n/locales/processed/cs.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_cs_json" */), cache: true }],
  "da": [{ key: "../src/i18n/locales/processed/da.json", load: () => import("../src/i18n/locales/processed/da.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_da_json" */), cache: true }],
  "de": [{ key: "../src/i18n/locales/processed/de.json", load: () => import("../src/i18n/locales/processed/de.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_de_json" */), cache: true }],
  "dum": [{ key: "../src/i18n/locales/processed/dum.json", load: () => import("../src/i18n/locales/processed/dum.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_dum_json" */), cache: true }],
  "el": [{ key: "../src/i18n/locales/processed/el.json", load: () => import("../src/i18n/locales/processed/el.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_el_json" */), cache: true }],
  "en": [{ key: "../src/i18n/locales/processed/en.json", load: () => import("../src/i18n/locales/processed/en.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_en_json" */), cache: true }],
  "es": [{ key: "../src/i18n/locales/processed/es.json", load: () => import("../src/i18n/locales/processed/es.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_es_json" */), cache: true }],
  "fi": [{ key: "../src/i18n/locales/processed/fi.json", load: () => import("../src/i18n/locales/processed/fi.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_fi_json" */), cache: true }],
  "fr": [{ key: "../src/i18n/locales/processed/fr.json", load: () => import("../src/i18n/locales/processed/fr.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_fr_json" */), cache: true }],
  "he": [{ key: "../src/i18n/locales/processed/he.json", load: () => import("../src/i18n/locales/processed/he.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_he_json" */), cache: true }],
  "hu": [{ key: "../src/i18n/locales/processed/hu.json", load: () => import("../src/i18n/locales/processed/hu.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_hu_json" */), cache: true }],
  "it": [{ key: "../src/i18n/locales/processed/it.json", load: () => import("../src/i18n/locales/processed/it.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_it_json" */), cache: true }],
  "ja": [{ key: "../src/i18n/locales/processed/ja.json", load: () => import("../src/i18n/locales/processed/ja.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_ja_json" */), cache: true }],
  "ko": [{ key: "../src/i18n/locales/processed/ko.json", load: () => import("../src/i18n/locales/processed/ko.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_ko_json" */), cache: true }],
  "ku": [{ key: "../src/i18n/locales/processed/ku.json", load: () => import("../src/i18n/locales/processed/ku.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_ku_json" */), cache: true }],
  "nl": [{ key: "../src/i18n/locales/processed/nl.json", load: () => import("../src/i18n/locales/processed/nl.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_nl_json" */), cache: true }],
  "no": [{ key: "../src/i18n/locales/processed/no.json", load: () => import("../src/i18n/locales/processed/no.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_no_json" */), cache: true }],
  "pl": [{ key: "../src/i18n/locales/processed/pl.json", load: () => import("../src/i18n/locales/processed/pl.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_pl_json" */), cache: true }],
  "pt": [{ key: "../src/i18n/locales/processed/pt.json", load: () => import("../src/i18n/locales/processed/pt.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_pt_json" */), cache: true }],
  "ro": [{ key: "../src/i18n/locales/processed/ro.json", load: () => import("../src/i18n/locales/processed/ro.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_ro_json" */), cache: true }],
  "ru": [{ key: "../src/i18n/locales/processed/ru.json", load: () => import("../src/i18n/locales/processed/ru.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_ru_json" */), cache: true }],
  "srcyrl": [{ key: "../src/i18n/locales/processed/sr.json", load: () => import("../src/i18n/locales/processed/sr.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_sr_json" */), cache: true }],
  "sv": [{ key: "../src/i18n/locales/processed/sv.json", load: () => import("../src/i18n/locales/processed/sv.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_sv_json" */), cache: true }],
  "tr": [{ key: "../src/i18n/locales/processed/tr.json", load: () => import("../src/i18n/locales/processed/tr.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_tr_json" */), cache: true }],
  "uk": [{ key: "../src/i18n/locales/processed/uk.json", load: () => import("../src/i18n/locales/processed/uk.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_uk_json" */), cache: true }],
  "zhS": [{ key: "../src/i18n/locales/processed/zh_hans.json", load: () => import("../src/i18n/locales/processed/zh_hans.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_zh_hans_json" */), cache: true }],
  "zhT": [{ key: "../src/i18n/locales/processed/zh_hant.json", load: () => import("../src/i18n/locales/processed/zh_hant.json" /* webpackChunkName: "locale__home_runner_work_Hangar_Hangar_frontend_src_i18n_locales_processed_zh_hant_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../src/i18n/i18n.config.ts?hash=29396fc9&config=1" /* webpackChunkName: "__src_i18n_i18n_config_ts_29396fc9" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": true,
    "fullInstall": true,
    "dropMessageCompiler": true
  },
  "compilation": {
    "jit": false,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./src/i18n/i18n.config.ts",
  "locales": [
    {
      "code": "af",
      "name": "Afrikaans",
      "files": [
        "i18n/locales/processed/af.json"
      ]
    },
    {
      "code": "ar",
      "name": "Arabic",
      "files": [
        "i18n/locales/processed/ar.json"
      ]
    },
    {
      "code": "bg",
      "name": "Bulgarian (Български)",
      "files": [
        "i18n/locales/processed/bg.json"
      ]
    },
    {
      "code": "ca",
      "name": "Catalan",
      "files": [
        "i18n/locales/processed/ca.json"
      ]
    },
    {
      "code": "cs",
      "name": "Czech",
      "files": [
        "i18n/locales/processed/cs.json"
      ]
    },
    {
      "code": "da",
      "name": "Danish",
      "files": [
        "i18n/locales/processed/da.json"
      ]
    },
    {
      "code": "de",
      "name": "Deutsch",
      "files": [
        "i18n/locales/processed/de.json"
      ]
    },
    {
      "code": "dum",
      "name": "In-Context Editor",
      "files": [
        "i18n/locales/processed/dum.json"
      ]
    },
    {
      "code": "el",
      "name": "Greek",
      "files": [
        "i18n/locales/processed/el.json"
      ]
    },
    {
      "code": "en",
      "name": "English",
      "files": [
        "i18n/locales/processed/en.json"
      ]
    },
    {
      "code": "es",
      "name": "Español",
      "files": [
        "i18n/locales/processed/es.json"
      ]
    },
    {
      "code": "fi",
      "name": "Finnish",
      "files": [
        "i18n/locales/processed/fi.json"
      ]
    },
    {
      "code": "fr",
      "name": "Français",
      "files": [
        "i18n/locales/processed/fr.json"
      ]
    },
    {
      "code": "he",
      "name": "Hebrew",
      "files": [
        "i18n/locales/processed/he.json"
      ]
    },
    {
      "code": "hu",
      "name": "Hungarian",
      "files": [
        "i18n/locales/processed/hu.json"
      ]
    },
    {
      "code": "it",
      "name": "Italiano",
      "files": [
        "i18n/locales/processed/it.json"
      ]
    },
    {
      "code": "ja",
      "name": "Japanese",
      "files": [
        "i18n/locales/processed/ja.json"
      ]
    },
    {
      "code": "ko",
      "name": "Korean",
      "files": [
        "i18n/locales/processed/ko.json"
      ]
    },
    {
      "code": "ku",
      "name": "Kurdish",
      "files": [
        "i18n/locales/processed/ku.json"
      ]
    },
    {
      "code": "nl",
      "name": "Nederlands",
      "files": [
        "i18n/locales/processed/nl.json"
      ]
    },
    {
      "code": "no",
      "name": "Norsk",
      "files": [
        "i18n/locales/processed/no.json"
      ]
    },
    {
      "code": "pl",
      "name": "Polish",
      "files": [
        "i18n/locales/processed/pl.json"
      ]
    },
    {
      "code": "pt",
      "name": "Portugese",
      "files": [
        "i18n/locales/processed/pt.json"
      ]
    },
    {
      "code": "ro",
      "name": "Romanian",
      "files": [
        "i18n/locales/processed/ro.json"
      ]
    },
    {
      "code": "ru",
      "name": "Russian",
      "files": [
        "i18n/locales/processed/ru.json"
      ]
    },
    {
      "code": "srcyrl",
      "name": "Serbian",
      "files": [
        "i18n/locales/processed/sr.json"
      ]
    },
    {
      "code": "sv",
      "name": "Swedish",
      "files": [
        "i18n/locales/processed/sv.json"
      ]
    },
    {
      "code": "tr",
      "name": "Türkçe",
      "files": [
        "i18n/locales/processed/tr.json"
      ]
    },
    {
      "code": "uk",
      "name": "Ukrainian",
      "files": [
        "i18n/locales/processed/uk.json"
      ]
    },
    {
      "code": "zhS",
      "name": "Simplified Chinese",
      "files": [
        "i18n/locales/processed/zh_hans.json"
      ]
    },
    {
      "code": "zhT",
      "name": "Traditional Chinese",
      "files": [
        "i18n/locales/processed/zh_hant.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "./i18n/locales/processed",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "af",
    "name": "Afrikaans",
    "files": [
      {
        "path": "i18n/locales/processed/af.json"
      }
    ]
  },
  {
    "code": "ar",
    "name": "Arabic",
    "files": [
      {
        "path": "i18n/locales/processed/ar.json"
      }
    ]
  },
  {
    "code": "bg",
    "name": "Bulgarian (Български)",
    "files": [
      {
        "path": "i18n/locales/processed/bg.json"
      }
    ]
  },
  {
    "code": "ca",
    "name": "Catalan",
    "files": [
      {
        "path": "i18n/locales/processed/ca.json"
      }
    ]
  },
  {
    "code": "cs",
    "name": "Czech",
    "files": [
      {
        "path": "i18n/locales/processed/cs.json"
      }
    ]
  },
  {
    "code": "da",
    "name": "Danish",
    "files": [
      {
        "path": "i18n/locales/processed/da.json"
      }
    ]
  },
  {
    "code": "de",
    "name": "Deutsch",
    "files": [
      {
        "path": "i18n/locales/processed/de.json"
      }
    ]
  },
  {
    "code": "dum",
    "name": "In-Context Editor",
    "files": [
      {
        "path": "i18n/locales/processed/dum.json"
      }
    ]
  },
  {
    "code": "el",
    "name": "Greek",
    "files": [
      {
        "path": "i18n/locales/processed/el.json"
      }
    ]
  },
  {
    "code": "en",
    "name": "English",
    "files": [
      {
        "path": "i18n/locales/processed/en.json"
      }
    ]
  },
  {
    "code": "es",
    "name": "Español",
    "files": [
      {
        "path": "i18n/locales/processed/es.json"
      }
    ]
  },
  {
    "code": "fi",
    "name": "Finnish",
    "files": [
      {
        "path": "i18n/locales/processed/fi.json"
      }
    ]
  },
  {
    "code": "fr",
    "name": "Français",
    "files": [
      {
        "path": "i18n/locales/processed/fr.json"
      }
    ]
  },
  {
    "code": "he",
    "name": "Hebrew",
    "files": [
      {
        "path": "i18n/locales/processed/he.json"
      }
    ]
  },
  {
    "code": "hu",
    "name": "Hungarian",
    "files": [
      {
        "path": "i18n/locales/processed/hu.json"
      }
    ]
  },
  {
    "code": "it",
    "name": "Italiano",
    "files": [
      {
        "path": "i18n/locales/processed/it.json"
      }
    ]
  },
  {
    "code": "ja",
    "name": "Japanese",
    "files": [
      {
        "path": "i18n/locales/processed/ja.json"
      }
    ]
  },
  {
    "code": "ko",
    "name": "Korean",
    "files": [
      {
        "path": "i18n/locales/processed/ko.json"
      }
    ]
  },
  {
    "code": "ku",
    "name": "Kurdish",
    "files": [
      {
        "path": "i18n/locales/processed/ku.json"
      }
    ]
  },
  {
    "code": "nl",
    "name": "Nederlands",
    "files": [
      {
        "path": "i18n/locales/processed/nl.json"
      }
    ]
  },
  {
    "code": "no",
    "name": "Norsk",
    "files": [
      {
        "path": "i18n/locales/processed/no.json"
      }
    ]
  },
  {
    "code": "pl",
    "name": "Polish",
    "files": [
      {
        "path": "i18n/locales/processed/pl.json"
      }
    ]
  },
  {
    "code": "pt",
    "name": "Portugese",
    "files": [
      {
        "path": "i18n/locales/processed/pt.json"
      }
    ]
  },
  {
    "code": "ro",
    "name": "Romanian",
    "files": [
      {
        "path": "i18n/locales/processed/ro.json"
      }
    ]
  },
  {
    "code": "ru",
    "name": "Russian",
    "files": [
      {
        "path": "i18n/locales/processed/ru.json"
      }
    ]
  },
  {
    "code": "srcyrl",
    "name": "Serbian",
    "files": [
      {
        "path": "i18n/locales/processed/sr.json"
      }
    ]
  },
  {
    "code": "sv",
    "name": "Swedish",
    "files": [
      {
        "path": "i18n/locales/processed/sv.json"
      }
    ]
  },
  {
    "code": "tr",
    "name": "Türkçe",
    "files": [
      {
        "path": "i18n/locales/processed/tr.json"
      }
    ]
  },
  {
    "code": "uk",
    "name": "Ukrainian",
    "files": [
      {
        "path": "i18n/locales/processed/uk.json"
      }
    ]
  },
  {
    "code": "zhS",
    "name": "Simplified Chinese",
    "files": [
      {
        "path": "i18n/locales/processed/zh_hans.json"
      }
    ]
  },
  {
    "code": "zhT",
    "name": "Traditional Chinese",
    "files": [
      {
        "path": "i18n/locales/processed/zh_hant.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
