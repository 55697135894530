import revive_payload_client_SWJPBj32Jf from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qlgxtfb7g7jgiklqddgsr6qo2q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0GZCS8cZ4I from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qlgxtfb7g7jgiklqddgsr6qo2q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2zUoZjLbyR from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qlgxtfb7g7jgiklqddgsr6qo2q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_f0Sia9x47K from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qlgxtfb7g7jgiklqddgsr6qo2q/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_gdlNHwGNFV from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qlgxtfb7g7jgiklqddgsr6qo2q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XaLyQFpEsN from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qlgxtfb7g7jgiklqddgsr6qo2q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jAeO2UUjb4 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qlgxtfb7g7jgiklqddgsr6qo2q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_z3mAdWrSx4 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qlgxtfb7g7jgiklqddgsr6qo2q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_M5nXWzb29V from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.19.2_typescript@5.5.4_vue@3.4.35_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_leTHzKLC25 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qlgxtfb7g7jgiklqddgsr6qo2q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_JcBqdxsWg5 from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.2_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_ss3K6MWRPH from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.2_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import unocss_MzCDxu9LMj from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/unocss.mjs";
import axios_OijlQmfXsA from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/axios.ts";
import dompurify_client_EYdHkZatd0 from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/dompurify.client.ts";
import nprogress_lI8WDWlVpm from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/nprogress.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/sentry.client.ts";
export default [
  revive_payload_client_SWJPBj32Jf,
  unhead_0GZCS8cZ4I,
  router_2zUoZjLbyR,
  debug_f0Sia9x47K,
  payload_client_gdlNHwGNFV,
  navigation_repaint_client_XaLyQFpEsN,
  check_outdated_build_client_jAeO2UUjb4,
  chunk_reload_client_z3mAdWrSx4,
  plugin_vue3_M5nXWzb29V,
  components_plugin_KR1HBZs4kY,
  prefetch_client_leTHzKLC25,
  switch_locale_path_ssr_JcBqdxsWg5,
  i18n_ss3K6MWRPH,
  unocss_MzCDxu9LMj,
  axios_OijlQmfXsA,
  dompurify_client_EYdHkZatd0,
  nprogress_lI8WDWlVpm,
  sentry_client_KAXFuL2wum
]